import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PatientCard from "../../components/PatientCard";
import { useNavigate } from "react-router-dom";
import "../immunizations/records.css";
import { Spinner } from "../../components/spinner/Spinner";
import { IconButton } from "../../components/IconButton/IconButton";
import { errorLog, infoLog } from "../../utils/logger";
import { DocketAPIError, ImmunizationSearchAPI, getAPIClient } from "../../apiClient";
import { ErrorModal } from "../../components/modals/ErrorModal";
import { getNextPollTime } from "../immunizations/SearchList";
import moment from "moment";
import {
  izSearchesAtom,
  loadablePatientsAtom,
  patientsAtom,
  refreshingSearchesOrRecordsAtom,
} from "../../jotai/atoms";
import { useAtom } from "jotai";
import { fetchRecordsUntilUpdated } from "../../utils/polling";
import { Squirrel } from "../../components/spinner/Squirrel";
import { USE_WHITELABEL_BRANDING, WHITELABEL_KEY } from "../../globals";
import { ImmunizationPatient } from "../../models/Interfaces";

export function Screening() {
  const [allPatients] = useAtom(loadablePatientsAtom);
  const [allSearches] = useAtom(izSearchesAtom);
  const [refreshing, setRefreshing] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState<string | boolean>();
  const [initialLoad, setInitialLoad] = useState(true);

  const navigate = useNavigate();
  const [backendRefreshing] = useAtom(refreshingSearchesOrRecordsAtom);

  const { t } = useTranslation();

  useEffect(() => {
    const hash = window.location.hash.slice(1);
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  const filteredPatients = React.useMemo(() => {
    if (allPatients.state !== "hasData") {
      return [];
    }

    // Now TypeScript knows allPatients has data property
    if (USE_WHITELABEL_BRANDING && WHITELABEL_KEY === "nj") {
      return allPatients.data.filter((patient) => patient.izProviderKey.toLowerCase() === "nj");
    }

    return allPatients.data;
  }, [allPatients]);

  useEffect(() => {
    if (!backendRefreshing) {
      setRefreshing(false);
    }
  }, [backendRefreshing]);

  const noPatients = () => {
    return (
      <div
        className="is-flex is-flex-direction-column is-align-items-center is-halfheight hero is-justify-content-center"
        data-testid="no-patients"
      >
        <h2 className="is-size-5 has-text-weight-bold has-text-black has-text-centered">
          {t("screening.search_screenings_message")}
        </h2>
        <br />
        <br />
        <button className="button docket-button pl-4 pr-4" onClick={() => navigate("/home/search")}>
          {t("screening.search_screenings_button")}
        </button>
      </div>
    );
  };

  useEffect(() => {
    if (allPatients.state === "hasData") {
      setInitialLoad(false);
    }
  }, [allPatients.state]);

  const refreshRecords = async () => {
    setRefreshing(true);
    await getAPIClient().refreshRecords();
    await fetchRecordsUntilUpdated();
  };

  const withPatients = () => {
    if (allPatients.state !== "hasData") return null;
    const lastUpdatedTime: Date | null = allSearches.reduce<Date | null>(
      (maxUpdatedDate, curSearch) => {
        const currentUpdatedDate = new Date(curSearch.dateUpdated);

        if (isNaN(currentUpdatedDate.getTime())) {
          return maxUpdatedDate;
        }

        return maxUpdatedDate === null || maxUpdatedDate < currentUpdatedDate
          ? currentUpdatedDate
          : maxUpdatedDate;
      },
      null
    );
    return (
      <div className="records-container">
        <div className="is-flex is-flex-direction-row is-align-items-center is-justify-content-space-between is-flex-wrap">
          <div className="level-left">
            <div>
              <p className="has-text-dark">
                <h4 className="has-text-dark title is-size-4 mb-2">
                  {t("screening.records_header")}
                </h4>
                <p className="has-text-dark is-size-6">{t("screening.records_subtitle")}</p>
                {lastUpdatedTime && (
                  <p className="has-text-dark is-size-6">
                    <b>{t("immunizations.updated")}</b> {moment(lastUpdatedTime).format("L")}
                  </p>
                )}
              </p>
            </div>
          </div>
          <div className="level-right mt-0">
            <div className="level-item has-text-centered">
              {refreshing ? (
                <Spinner />
              ) : (
                <IconButton
                  icon="refresh"
                  onClick={() => refreshRecords()}
                  alt={"refresh results"}
                />
              )}
            </div>
          </div>
        </div>
        <div className="separater"></div>
        <ul className="patient-list">
          {(filteredPatients || []).map((patient, index) => (
            <li id={index.toString()} key={index}>
              <PatientCard patient={patient} index={index} screening />
            </li>
          ))}
        </ul>
      </div>
    );
  };

  if (allPatients.state === "hasError") {
    setShowErrorModal(t("screening.search_records_error"));
  }

  return (
    <div className="columns is-0">
      {showErrorModal && (
        <ErrorModal onCloseModal={() => setShowErrorModal(false)}>{showErrorModal}</ErrorModal>
      )}
      <div className="column is-gapless">
        {allPatients.state === "loading" ||
          (initialLoad && (
            <div className="is-flex is-flex-direction-column is-align-items-center is-halfheight hero is-justify-content-center">
              <Squirrel />
            </div>
          ))}
        <div className={`fade-in ${!initialLoad ? "visible" : ""}`}>
          {allPatients.state === "hasData" && !initialLoad && allPatients.data.length > 0
            ? withPatients()
            : noPatients()}
        </div>
      </div>
    </div>
  );
}

export default Screening;
